<template>
    <div class="mt-n6 div-job-details">
        <div class="pt-10 d-block">
            <label class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2" style="border-bottom: 1px solid;">
                รายการ
            </label>
        </div>

        <v-container class="pb-16 mt-3" v-resize="onResize" :style="{width: windowSize.w + 'px'}"
            style="background-color: #1F1C1B">
            <v-card class="px-10 text-left card-details" dark>
                <v-card-title class="yellow--text text--darken-3">
                    ยืนยันการจัดส่ง
                </v-card-title>
                <v-card-subtitle>
                    โปรดตรวจสอบความถูกต้องของข้อมูลก่อนกดยืนยัน
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <label class="d-block text-body-1 font-weight-bold white--text mb-1">ข้อมูลส่วนตัว</label>
                            <v-row>
                                <v-col>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ประเภท</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{ job.customer.type
                                            == 'person' ? 'บุคคลธรรมดา' : 'นิติบุคคล' }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text" style="width: 30%">ชื่อ -
                                            นามสกุล</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{ job.customer.name
                                            }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">เบอร์โทร</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{ mobileText }}
                                        </label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">อีเมล</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{ job.customer.email
                                            }} </label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ที่อยู่</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{
                                            job.customer.address }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text" style="width: 30%">ID /
                                            Tax</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{
                                            idTaxText }}</label>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <label class="d-block text-body-1 font-weight-bold white--text mb-1">ข้อมูลการจัดส่ง</label>
                            <v-row>
                                <v-col>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">ตัวเลือกการจัดส่ง</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{ channelText
                                            }}</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="text-body1 d-inline-block grey--text"
                                            style="width: 30%">การจัดส่ง</label>
                                        <label class="text-body1 white--text" style="width: 100%">{{ job.ordering.type
                                            == 1 ? 'รับส่ง' : 'ไปกลับ' }}</label>
                                    </div>
                                    <div v-if="job.ordering.type == 1">
                                        <div class="d-flex">
                                            <label class="text-body1 d-inline-block grey--text"
                                                style="width: 30%">ต้นทาง</label>
                                            <label class="text-body1 white--text" style="width: 100%">{{
                                                address1StartText }}</label>
                                        </div>
                                        <div class="d-flex">
                                            <label class="text-body1 d-inline-block grey--text"
                                                style="width: 30%">ปลายทาง</label>
                                            <label class="text-body1 white--text" style="width: 100%">{{
                                                address1DestinationText }}</label>
                                        </div>
                                    </div>
                                    <div v-if="job.ordering.type == 2">
                                        <label class="d-block font-weight-bold">ขาไป</label>
                                        <div class="d-flex">
                                            <label class="text-body1 d-inline-block grey--text"
                                                style="width: 30%">ต้นทาง</label>
                                            <label class="text-body1 white--text" style="width: 100%">{{
                                                address1StartText }}</label>
                                        </div>
                                        <div class="d-flex">
                                            <label class="text-body1 d-inline-block grey--text"
                                                style="width: 30%">ปลายทาง</label>
                                            <label class="text-body1 white--text" style="width: 100%">{{
                                                address1DestinationText }}</label>
                                        </div>
                                        <label class="d-block font-weight-bold">ขากลับ</label>
                                        <div class="d-flex">
                                            <label class="text-body1 d-inline-block grey--text"
                                                style="width: 30%">ต้นทาง</label>
                                            <label class="text-body1 white--text" style="width: 100%">{{
                                                address2StartText }}</label>
                                        </div>
                                        <div class="d-flex">
                                            <label class="text-body1 d-inline-block grey--text"
                                                style="width: 30%">ปลายทาง</label>
                                            <label class="text-body1 white--text" style="width: 100%">{{
                                                address2DestinationText }}</label>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="text-left card-details mt-8" dark>
                <v-card-text>
                    <label class="d-block text-body-1 font-weight-bold white--text my-2 px-10">ข้อมูลสินค้า</label>
                    <v-data-table id="product-table" class="px-5" :headers="header" :items="job.products" :dark="false"
                        style="background: none !important;" hide-default-footer>
                        <template v-slot:[`item.name`]="{ item }">
                            <div class="d-flex">
                                <v-img class="d-inline-flex my-2"
                                    src="@/assets/box.png" max-height="50" max-width="50">
                                </v-img>
                                <div class="ml-4 d-block pa-2 my-auto">
                                    <label class="d-block font-weight-bold">{{ item.details ? item.details :
                                        transformProductType(item.type) }}</label>
                                    <label class="d-block grey--text">ขนาด {{ item.weight.value }} {{
                                        transformWeightUnitText(item.weight.unit) }}</label>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.weight`]="{item}">
                            <label> {{ `${item.weight.value} ${transformWeightUnitText(item.weight.unit)}` }} </label>
                        </template>
                        <template v-slot:[`item.quantity`]="{item}">
                            <label v-if="item.quantity"> {{ `${item.quantity.value}
                                ${transformQuantityUnitText(item.quantity.unit)}` }} </label>
                            <label v-else>-</label>
                        </template>
                        <template v-slot:[`item.price`]="{item}">
                            <label class="font-weight-bold"> {{ numberToLocalText(item.price) }} บาท</label>
                        </template>
                    </v-data-table>

                    <div class="mt-10 mx-10">
                        <v-row no-gutters>
                            <v-col sm="8" cols="0"></v-col>
                            <v-col sm="4" col="12">
                                <div class="d-flex justify-space-between">
                                    <label class="text-body1 d-inline-block grey--text">รวมราคาสินค้า</label>
                                    <label class="text-body1 white--text"> {{ totalPrice }} บาท </label>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col sm="8" cols="0"></v-col>
                            <v-col sm="4" col="12">
                                <div class="d-flex justify-space-between">
                                    <label class="text-body1 d-inline-block grey--text">น้ำหนัก</label>
                                    <label class="text-body1 white--text"> {{ orderingWeightValueText }} {{
                                        orderingWeightUnitText }} </label>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- <v-row no-gutters>
                            <v-col sm="8" cols="0"></v-col>
                            <v-col sm="4" col="12">
                                <div class="d-flex justify-space-between">
                                    <label class="text-body1 d-inline-block grey--text">ขนาด</label>
                                    <label class="text-body1 white--text"> {{ job.ordering.width }} x {{ job.ordering.length }} x {{ job.ordering.height }} cm. </label>
                                </div>
                            </v-col>
                        </v-row> -->
                        <v-row no-gutters>
                            <v-col sm="8" cols="0"></v-col>
                            <v-col sm="4" col="12" style="border-bottom: 0.5px solid #f9a825;">
                                <div class="d-flex justify-space-between">
                                    <label class="text-h6 d-inline-block grey--text">ราคาจัดส่ง</label>
                                    <label class="text-h6 yellow--text text--darken-3"> {{ shippingFee }} บาท</label>
                                </div>
                            </v-col>
                        </v-row>

                    </div>
                </v-card-text>
            </v-card>

            <v-card class="text-left card-details mt-8 px-10" dark v-if="'request_create' != job.ordering.subStatus">
                <v-card-text>
                    <label class="d-block text-body-1 font-weight-bold white--text my-2">วิธีชำระเงิน</label>
                    <v-radio-group row v-model="job.ordering.paymentType"
                        :disabled="'deal_shipping' != job.ordering.subStatus">
                        <v-radio v-if="job.channel != '3'" color="yellow darken-3" label="เก็บเงินปลายทาง" value="1">
                        </v-radio>
                        <v-radio color="yellow darken-3" label="โอนเงินผ่านธนาคาร หรือ Mobile Banking" value="2">
                        </v-radio>
                        <v-radio v-if="job.customer.type == 'corporation'" color="yellow darken-3" label="เครดิต"
                            value="3"></v-radio>
                    </v-radio-group>
                    <div class="d-flex" v-if="job.ordering.paymentType == 2">
                        <div>
                            <label class="d-block font-weight-bold">ชื่อบัญชี บริษัท มูฟไว จำกัด</label>
                            <label class="d-block font-weight-bold">เลขที่บัญชี 252-2-34037-7</label>
                            <label class="d-block font-weight-bold">ธนาคารทีเอ็มบีธนชาต</label>
                            <label class="d-block">สาขายูไนเต็ด เซ็นเตอร์ (สีลม)</label>
                        </div>
                        <div v-if="job.ordering.subStatus == 'deal_shipping'" class="my-auto flex-grow-1">
                            <v-file-input class="ml-3" v-model="paymentFile" dark color="yellow darken-3"
                                label="หลักฐานการจ่ายเงิน"
                                :rules="[ v => !!v && job.ordering.paymentType == 2 || 'โปรดแนบไฟล์']"></v-file-input>
                        </div>
                    </div>
                    <div class="mt-5" v-if="job.ordering.subStatus != 'deal_shipping' && job.ordering.paymentType == 2">
                        <v-img class="mx-auto" max-height="200"
                            max-width="180" contain :src="paymentFileUrl"
                            @click="$refs.dialogImage.show(paymentFileUrl);"></v-img>
                    </div>

                    <div class="d-flex" v-if="job.ordering.paymentType == 3">
                        <v-text-field v-model="job.ordering.paymentNote"
                            :disabled="'deal_shipping' != job.ordering.subStatus" label="* หมายเหตุ"
                            color="yellow darken-3"
                            :rules="[ v => !!v && job.ordering.paymentType == 3 || 'โปรดกรอกข้อมูล']">
                        </v-text-field>
                    </div>
                </v-card-text>
            </v-card>

            <div v-if="job.ordering.subStatus == 'deal_shipping'" class="d-flex justify-space-between my-10">
                <div>
                    <v-btn width="200" rounded outlined color="yellow darken-3"
                        @click="$refs.dialogReject.show(job.id)">
                        ยกเลิก
                    </v-btn>
                </div>
                <div>
                    <v-btn width="200" rounded color="yellow darken-3" @click="$refs.dialogConfirmAccept.show()">
                        ตกลงราคา
                    </v-btn>
                </div>
            </div>
        </v-container>

        <dialog-error ref="dialogValidateFail" :topic="'ข้อมูลไม่ถูกต้อง'" :text="'กรุณากรอกข้อมูลใหม่อีกครั้ง'" />
        <dialog-error ref="dialogError" :topic="'Error'" :text="errorMessage" />
        <dialog-success ref="dialogSuccess" :text="'ยืนยันสำเร็จแล้ว'" :redirectTo="{ name: 'MyOrder' }" />
        <dialog-confirm :title="'ยืนยันการตกลงราคา'" :description="'โปรดตรวจสอบให้แน่ใจก่อนทำการยืนยัน'"
            @confirm="accept" ref="dialogConfirmAccept" />
        <dialog-image ref="dialogImage" />
        <dialog-reject @finishReject="finishReject" ref="dialogReject" />
    </div>
</template>

<script>
import DialogError from '../components/DialogError.vue';
import DialogSuccess from '../components/DialogSuccess.vue';
import DialogConfirm from '../components/DialogConfirm.vue';
import DialogImage from '../components/DialogImage.vue';
import DialogReject from '../components/DialogReject.vue';
import { getJobById, initForm, quantityUnitText, acceptDealShipping } from '../models/job';
import * as Text from '../utils/text-mapping';
import axios from 'axios';

export default {
    data: () => ({
        errorMessage: '',
        windowSize: {},
        job: initForm(),
        paymentFile: null,
        selectedImage: null,
        header: [
            {
                text: 'รายการสินค้า',
                sortable: true,
                value: 'name'
            },
            {
                text: 'น้ำหนัก',
                sortable: true,
                value: 'weight'
            },
            {
                text: 'จำนวณสินค้า',
                sortable: true,
                value: 'quantity'
            },
            {
                text: 'มูลค่าสินค้า',
                sortable: true,
                value: 'price'
            }
        ],
        paymentFileUrl: null
    }),
    methods: {
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        finishReject(){
            this.$refs.dialogSuccess.show();
        },
        async accept(){
            const loader = this.$loading.show();
            const id = this.$route.params.id;
            const paymentType = this.job.ordering.paymentType;
            try{
                const formData = new FormData();
                formData.append('paymentType', paymentType);
                if('2' == paymentType){
                    formData.append('paymentFile', this.paymentFile);
                }else if('3' == paymentType){
                    formData.append('paymentNote', this.job.ordering.paymentNote);
                }
                await acceptDealShipping(id, formData);
                this.$refs.dialogSuccess.show();
            }catch(error){
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.dialogError.show();
            }finally{
                loader.hide();
            }
        },
        transformWeightUnitText(value){
            if('g' == value){
                return 'กรัม';
            }else if('kb' == value){
                return 'กิโลกรัม';
            }else if('b' == value){
                return 'บาท';
            }else{
                return value;
            }
        },
        transformQuantityUnitText(value){
            return quantityUnitText[value] || value;
        },
        numberToLocalText(number){
            return Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        async fetchJob(){
            const loader = this.$loading.show();
            const id = this.$route.params.id;
            try{
                this.job = await getJobById(id);
                if(this.job.ordering && 2 == this.job.ordering.paymentType){
                    axios.get(this.job.ordering.paymentFile, { responseType: 'blob' })
                        .then(response => {
                            this.paymentFileUrl = window.URL.createObjectURL(response.data);
                        });
                }
            }catch(error){
                this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                this.$refs.dialogError.show();
            }finally{
                loader.hide();
            }
        },
        transformProductType(type){
            return Text.productTypeToText(type);
        }
    },
    async mounted(){
        await this.fetchJob();
    },
    computed: {
        mobileText(){
            if(this.job.customer && this.job.customer.mobile){
                const mobile = this.job.customer.mobile;
                return mobile.substring(0, 3) + '-' + mobile.substring(3, 10);
            }else{
                return '';
            }
        },
        idTaxText () {
            const idTax = this.job.customer.idTax;
            if (idTax && 13 == idTax.length) {
                return `${idTax[0]}-${idTax[1]}${idTax[2]}${idTax[3]}${idTax[4]}-${idTax[5]}${idTax[6]}${idTax[7]}${idTax[8]}${idTax[9]}-${idTax[10]}${idTax[11]}-${idTax[12]}`;
            } else {
                return idTax;
            }
        },
        totalPrice(){
            let totalPrice = 0;
            this.job.products.forEach(p => {
                totalPrice += Number(p.price);
            });
            return totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        shippingFee(){
            return this.job.ordering.shippingFee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0.00';
        },
        orderingWeightUnitText(){
            const unit = this.job.ordering.sumWeight?.unit || '';
            if('g' == unit){
                return 'กรัม';
            }else if('kg' == unit){
                return 'กิโลกรัม';
            }else {
                return '';
            }
        },
        orderingWeightValueText(){
            return this.job.ordering.sumWeight?.value || '';
        },
        sumProductsPrice(){
            const products = this.job.products;
            let price = 0;
            products.forEach(p => {
                price += Number(p.price);
            });
            price += Number(this.job.ordering.shippingFee);
            return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        channelText(){
            return Text.channelText(this.job.channel);
        },
        address1StartText(){
            return this.job.ordering.address1 ? this.job.ordering.address1.start.address2 + ' ' + this.job.ordering.address1.start.address1 : '';
        },
        address1DestinationText(){
            return this.job.ordering.address1 ? this.job.ordering.address1.destination.address2 + ' ' + this.job.ordering.address1.destination.address1 : '';
        },
        address2StartText(){
            return this.job.ordering.address2 ? this.job.ordering.address2.start.address2 + ' ' + this.job.ordering.address2.start.address1 : '';
        },
        address2DestinationText(){
            return this.job.ordering.address2 ? this.job.ordering.address2.destination.address2 + ' ' + this.job.ordering.address2.destination.address1 : '';
        }
        
    },
    watch: {
        '$route.params.id': {
            async handler(){
                await this.fetchJob();
            },
            deep: true
        }
    },
    components: {
        DialogError,
        DialogSuccess,
        DialogConfirm,
        DialogImage,
        DialogReject
    }
};
</script>

<style scoped>
    .div-job-details{
        text-align: center;
    }
    .card-details{
        background-color: #0F0E0D;
    }
    
</style>

<style lang="scss">  
  #product-table {
    tbody {
        tr:hover {
            background-color: transparent !important;
        }
    }
  }
</style>