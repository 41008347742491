<template>
    <v-dialog
        v-model="dialog"
        width="420"
        overlay-opacity="0.8"
        persistent
    >
        <v-card color="white" class="pa-5" align="center">
            <v-icon class="mt-3" color="yellow darken-3" size="50">
                {{ icon }}
            </v-icon>
            <label v-if="topic" cy-data="dialogTopic" class="mb-n5 mt-4 d-block font-weight-bold black--text">{{ topic }}</label>
            <p class="mt-5 grey--text text--darken-3" cy-data="dialogMessage">{{ text }}</p>
            <v-btn v-if="!autoClose" rounded color="yellow darken-3" width="130" @click="hide">ตกลง</v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        topic: { type: String, default: '' },
        text: { type: String, default: '' },
        redirectTo: { type: Object, default: () => ({ name: 'Home' }) }, // can use when autoclose is true
        icon: { type: String, default: 'mdi-checkbox-marked-circle-outline' },
        delayClose: { type: Number, default: 2000 },
        autoClose: { type: Boolean, default: true }
    },
    data: () => ({
        dialog: false
    }),
    methods: {
        show(){
            this.dialog = true;
            if(this.autoClose){
                setTimeout(() => {
                    if(this.redirectTo){
                        this.$router.replace(this.redirectTo);
                    }else{
                        this.hide();
                    }
                }, this.delayClose);
            }
            
        },
        showThenRefresh(){
            this.dialog = true;
            if(this.autoClose){
                setTimeout(() => {
                    window.location.reload();
                }, this.delayClose);
            }
            
        },
        hide(){
            this.dialog = false;
            this.$emit('hide');
        }
    },
    mounted(){

    }
};
</script>